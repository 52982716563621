<!--    todo  将创建页面的deptCode值代入pickU页面
-->
<template>
    <div class="SurplusCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            style="text-align: left"
            v-model="form"
            :model="form"
            :rules="rules"
            ref="createForm"
            size="small"
            label-width="80px"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row>
                    <ef-dept-type-and-dept-select
                        ref="deptTypeAndDept"
                        @selectDept="setDept"
                        @selectDeptType="setDeptType"
                    />
                    <el-col :span="8">
                        <el-form-item label="查询条件" prop="search">
                            <el-input placeholder="商品名称/条码/助记码/自编码" v-model="search" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="$store.state.session.name" style="width: 215px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input readonly :value="createTime" style="width: 215px" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="form.remark" />
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button type="primary" :disabled="disableQueryAndAddButton" @click="queryAndAdd" size="small">
                    查询并添加
                </el-button>
                <el-button
                    type="primary"
                    :disabled="disableShowPickUpGoodsDialog"
                    @click="showPickUpGoods"
                    size="small"
                >
                    选择商品基本信息
                </el-button>
                <el-button
                    type="primary"
                    :loading="saveLoading"
                    :disabled="disableSaveButton"
                    @click="handleSave"
                    size="small"
                    >保存</el-button
                >
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="440"
                >
                    <el-table-column label="序号" type="index" width="50" align="center" fixed="left" />
                    <el-table-column label="商品名称" width="180">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="条码" width="150">
                        <template slot-scope="scope">
                            {{
                                skuByGoodsCode(scope.row.goodsCode)
                                    .bars.map((d) => d)
                                    .join(' / ')
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column label="商品类型" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).type | skuTypeDesc }}
                        </template>
                    </el-table-column>
                    <el-table-column label="助记码" width="100">
                        <template slot-scope="scope">
                            {{ goodsByCode(scope.row.goodsCode).fastBar }}
                        </template>
                    </el-table-column>
                    <el-table-column label="自编码" width="100">
                        <template slot-scope="scope">
                            {{ goodsByCode(scope.row.goodsCode).customBar }}
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                        </template>
                    </el-table-column>
                    <el-table-column label="类目" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).category }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                        </template>
                    </el-table-column>
                    <el-table-column label="实际补货数量" width="170">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.counts'" :rules="rules.counts">
                                <ef-stock-biz-count-input
                                    v-model="scope.row.counts"
                                    :sku-type="skuByGoodsCode(scope.row.goodsCode).type"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
            <pick-up-goods ref="pickupGoods" :selfCanClose="false" @queryGoods="setSelectedGoods" />
        </el-form>
    </div>
</template>

<script>
import Util from 'js/Util';
import StockBizCommon, { PropertyConverter, wholeSalePrice } from 'js/StockBizCommon';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import PickUpGoods from 'components/PickUpGoods';
import EfRemark from 'components/EfRemark';
import EfStockBizCountInput from 'components/EfStockBizCountInput';
import GoodsCommon from 'js/GoodsCommon';
import {
    deptCodeValidateRule,
    goodsCodeValidateRule,
    remarkNotRequiredValidateRule,
} from 'js/validate/ValidateCommonRule';
import {
    abstractCodeValidateRule,
    countGreaterThanZeroValidateRule,
    countValidateRule,
} from 'js/validate/ValidateStockBizRule';
import MoneyUtils from 'js/MoneyUtils';
export default {
    name: 'ReplenishmentCreate',
    components: {
        EfDeptTypeAndDeptSelect,
        PickUpGoods,
        EfRemark,
        EfStockBizCountInput,
    },
    data() {
        return {
            routerName: 'menu.mall.manage.repay.goods.create',
            search: '',
            adjustAbstractType: StockBizCommon.AdjustAbstractTypeEnum.OVERFLOW,
            form: {
                deptCode: '',
                deptName: '',
                remark: '',
                abstractCode: '',
                detailParams: [],
            },
            createTime: Util.nowFormatDate(),

            //所选商品详情列表
            goodsDetailMap: new Map(),
            rules: {
                deptCode: deptCodeValidateRule(),
                abstractCode: abstractCodeValidateRule(),
                remark: remarkNotRequiredValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                counts: [
                    countValidateRule(
                        () => (this.form || {}).detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                    countGreaterThanZeroValidateRule(),
                ],
            },
            //有初始机构
            haveInitDept: false,
            saveLoading: false,
        };
    },
    computed: {
        //禁用/启用按钮
        disableQueryAndAddButton() {
            return Util.isEmpty(this.form.deptCode) || Util.isEmpty(this.search);
        },
        //禁用/启用按钮
        disableShowPickUpGoodsDialog() {
            return Util.isEmpty(this.form.deptCode);
        },
        //禁用/启用按钮
        disableSaveButton() {
            return (
                Util.isEmpty(this.form.deptCode) ||
                this.form.detailParams == null ||
                this.form.detailParams.length === 0
            );
        },
    },
    watch: {
        'form.deptCode': function (newDeptCode, oldDeptCode) {
            if (!this.haveInitDept) {
                //清空商品列表数据
                this.form.detailParams = [];
            } else {
                this.haveInitDept = false;
            }
        },
        $route: {
            immediate: true,
            handler: function (to, from) {
                if (to.name == this.routerName) {
                    this.initGoodsSelectData(to.params);
                }
            },
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
    },
    methods: {
        detailParamPropertyArrForAdd() {
            return [
                'goodsCode',
                new PropertyConverter(null, 'counts'),
                new PropertyConverter(wholeSalePrice, 'showPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
            ];
        },
        detailParamPropertyArrForSubmit() {
            return [
                'goodsCode',
                'counts',
                new PropertyConverter('showPrices', 'wholeSalePrices', (price) => {
                    return MoneyUtils.moneyToDb(price);
                }),
            ];
        },
        handleSave() {
            this.$refs.createForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                if (this.form.detailParams.length > 150) {
                    this.$message.error('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
                    return false;
                }
                this.saveLoading = true;
                //将商品详情列表转换成多字段数组形式
                const createParam = {
                    ...this.form,
                    ...StockBizCommon.convertStockBizDetailParamsToParamArrObj(
                        this.form.detailParams,
                        this.detailParamPropertyArrForSubmit()
                    ),
                };
                //删除非提交必要字段数据
                delete createParam.detailParams;

                const _params = [];
                this.form.detailParams.forEach((item) => {
                    _params.push({
                        goodsCode: item.goodsCode,
                        repayGoodsCount: item.counts,
                    });
                });
                this.$http
                    .post(
                        `/repayDeptGoods/createExtend?deptCode=${createParam.deptCode}&remark=${this.form.remark}`,
                        _params
                    )
                    .then((rst) => {
                        this.goBackAndReload();
                        this.saveLoading = false;
                    });
                this.saveLoading = false;
            });
        },
        showPickUpGoods() {
            this.$refs.pickupGoods.show({ deptCode: this.form.deptCode }, this.form.deptName);
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ deptCode: this.form.deptCode, search: this.search });
            this.setSelectedGoods(rst.data);
        },
        handleDelete(index) {
            this.form.detailParams.splice(index, 1);
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.deptType = deptType;
        },
        setSelectedGoods(selectedGoods) {
            const oldDetails = Object.assign([], this.form.detailParams || []);
            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                oldDetails,
                this.detailParamPropertyArrForAdd()
            );
            if (oldDetails.length <= 150) {
                this.form.detailParams = oldDetails;
                this.$refs.pickupGoods.dialogFormVisible = false;
            } else {
                this.$message.warning('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
            }
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        initGoodsSelectData(params) {
            this.$nextTick(() => {
                if (Object.keys(params).length > 0) {
                    this.haveInitDept = true;
                    this.$refs.deptTypeAndDept.initNowPage(params.goodsPageDeptCode);
                    this.setSelectedGoods(params.goodsPageSelect);
                } else {
                    this.haveInitDept = false;
                    this.$refs.deptTypeAndDept.initNowPage('');
                }
            });
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
